<template>
    <div class="box">
        <div class="jbxx">
            <div class="jbtop">
                <div class="icon"></div>
                <div class="tit">基本信息</div>
            </div>
            <el-form ref="form" :model="form" label-width="200px">
              <el-row>
                <el-col :span="7">
                  <el-form-item label="上架店铺">
                    <el-input @focus="focus(1)" v-model="form.belongBusinessName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label="一级分类">
                    <el-input @focus="focusfl(1)" v-model="form.parentClassifyName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label="二级分类">
                    <el-input @focus="focusfltwo()" v-model="form.classifyName"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="7">
                    <el-form-item label="商品名称">
                      <el-input v-model="form.commodityName"></el-input>
                   </el-form-item>  
                </el-col>
                <el-col :span="7">
                    <el-form-item label="商品排序">
                      <el-input v-model="form.orderSq"></el-input>
                   </el-form-item>  
                </el-col>
                <!-- <el-col :span="7">
                    <el-form-item label="分类名称">
                      <el-input v-model="form.classify"></el-input>
                   </el-form-item>  
                </el-col> -->
                <!-- <el-col :span="7">
                    <el-form-item label="上架店铺">
                      <el-input @focus="focus" v-model="form.businessName"></el-input>
                   </el-form-item>  
                </el-col> -->
              </el-row>
              <el-row>
                <el-col :span="7">
                    <el-form-item label="库存为0时是否继续售卖">
                      <el-switch
                      v-model="form.sellWithNoInventory"
                      active-value="1"
                      inactive-value="0"
                      >
                      </el-switch>
                   </el-form-item>  
                </el-col>
                <el-col :span="7">
                      <el-form-item label="该商品是否不计入库存">
                          <el-switch
                          v-model="form.unInventory"
                          active-value="1"
                          inactive-value="0"
                          >
                          </el-switch>
                       </el-form-item>  
                </el-col>
                <el-col :span="7">
                        <el-form-item label="是否上推荐">
                          <el-switch
                          v-model="form.isRecommend"
                          active-value="1"
                          inactive-value="0"
                          >
                          </el-switch>
                       </el-form-item>  
                </el-col>
                
              </el-row>
              <div style="display:flex;">
                <div class="smalltext" style="margin-left: 20px;">商品规格：</div>
                <el-button type="primary" @click="addgg">新增规格</el-button>
              </div>
              <el-row>
                <div v-for='(item,i) in commoditySpecsList' :key='i' style="margin-left: 145px;">
                <div style="display: flex;margin-top: 15px;">
                  <div class="ggli" style="width: 100px;">
                    <div class="smalltext" style="text-align: center;">规格名称</div>
                    <el-input style="width: 100px;" type="text" v-model="item.specificationName"></el-input>
                  </div>
                  <div class="ggli" style="width: 100px;">
                    <div class="smalltext" style="text-align: center;">库存</div>
                    <el-input style="width: 100px;" type="text" v-model="item.inventory"></el-input>
                  </div>
                  <div class="ggli" style="width: 100px;">
                    <div class="smalltext" style="text-align: center;">零售价</div>
                    <el-input style="width: 100px;" type="text" v-model="item.realPrice"></el-input>
                    <!-- buyPrice -->
                  </div>
                  <div class="ggli" style="width: 100px;">
                    <div class="smalltext" style="text-align: center;">划线价</div>
                    <el-input style="width: 100px;" type="text" v-model="item.retailPrice"></el-input>
                  </div>
                  <div class="ggli" style="width: 100px;">
                    <div class="smalltext" style="text-align: center;">打包费</div>
                    <el-input style="width: 100px;" type="text" v-model="item.packingPrice"></el-input>
                  </div>
                  
                </div>
                
                </div>
                
              </el-row>
              <div style="display:flex;margin-top: 30px">
                <div class="smalltext" style="margin-left: 20px;">做法：</div>
                <el-button class='tablist-btn' type="primary" @click="addzf">新增做法</el-button>
              </div>
              
              <div class="tab">
                <div class='tablist' style="align-items: center;" v-for='(item,i) in commodityMethodList' :key='i'>
                  <div class='smalltext listleft'>{{item.content}}</div>
                  <div class='listright'>
                    <el-tag
                      :key="tag"
                      v-for="tag in item.methodStringList"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(i,tag)">
                      {{tag}}
                    </el-tag>
                    <el-input
                      class="input-new-tag"
                      v-if="inputVisible === i"
                      v-model="inputValue"
                      ref="saveTagInput"
                      size="small"
                      @input="limitInputLength"
                      @keyup.enter.native="handleInputConfirm(i)"
                      @blur="handleInputConfirm(i)"
                    >
                    </el-input>
                    <div>
                      <el-button class="button-new-tag" size="small" @click="showInput(i)">+</el-button>
                      <el-button class="button-new-tag" size="small" @click="deleteMethod(i)" icon="el-icon-delete"></el-button>
                    </div>
                  </div>
                </div>
                
              </div>
              <el-col :span="24">
                    <el-form-item label="商品logo：" prop="contactName">
                      <!-- <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccessfour"
                        >
                        <img v-if="form.logoImg" :src="form.logoImg" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload> -->
                      <div class="list-img-box">
                        <div v-if="form.logoImg !== ''" class="imgBox">
                          <img class="upload-img" :src="form.logoImg" style='width:150px;height:150px' @click="uploadPicture('logoImg')" alt="自定义商品logo">
                          <div class="img-icon">
                            <i class="el-icon-zoom-in" @click.stop="onPreview(form.logoImg)"></i>
                            <i class="el-icon-delete" @click.stop="handelRemove('logo')"></i>
                          </div>
                        </div>
                        <div v-else class="upload-btn" style="" @click="uploadPicture('logoImg')">
                          <i class="el-icon-plus" style="font-size: 30px;"></i>
                        </div>
                      </div>
                      <div style="font-size: 12px;padding-left: 5px;color: #60627f;">图片建议尺寸(1 × 1)</div>
                    </el-form-item>
                </el-col>
              <!-- <el-col :span="24">
                    <el-form-item label="商品封面图：" prop="contactName">
                      <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccessthree"
                        >
                        <img v-if="form.mainImg" :src="form.mainImg" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </el-form-item>
                </el-col> -->
                <el-col :span="24">
            <el-form-item label="商品详情轮播：" prop="contactName">
              <!-- <el-upload
                class="avatar-uploader xin"
                :action="$uploadURL"
                :show-file-list="false"
                :on-success="handleSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <div v-if="list.length > 0" class="flex">
                  <div
                    style="margin: 5px"
                    class="imgBox"
                    v-for="(item, index) in list"
                    :key="index"
                  >
                    <img :src="item" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="onPreview(item)"
                      ></i>
                      <i class="el-icon-delete" @click.stop="handelRemove(index)"></i>
                    </div>
                  </div>
                </div>
                <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload> -->
              <div v-if="list.length > 0" class="flex">
                <div
                  class="imgBox"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <img :src="item" class="upload-img" alt="" />
                  <div class="img-icon">
                    <i class="el-icon-zoom-in" @click.stop="onPreview(item)"></i>
                    <i class="el-icon-delete" @click.stop="handelRemove(index)"></i>
                  </div>
                </div>
              </div>
                <div class="list-img-box">
                  <div class="upload-btn" style="" @click="uploadPicture('bigImgList')">
                    <i class="el-icon-plus" style="font-size: 30px;"></i>
                  </div>
                </div>
              <div style="font-size: 12px;padding-left: 5px;color: #60627f;">图片建议尺寸(750 × 343)</div>
            </el-form-item>
            <!-- <el-form-item label="商品大图：" prop="contactName">
              <el-upload class="avatar-uploader" :action="$uploadURL"
                :show-file-list="false" :on-success="handleAvatarSuccess">
                <img v-if="form.bigImg" :src="form.bigImg" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item> -->
          </el-col>
                <el-col :span="24">
                    <el-form-item label="商品视频：" prop="contactName">
                      <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccesstwo"
                        >
                        <video v-if="form.buyVideo" :src="form.buyVideo" class="avatar" controls></video>
                        
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </el-form-item>
                </el-col>
                
                
            </el-form>
            <div class="but" style="text-align: center;">
            <el-button size="medium" @click="close()">取消 </el-button>
            <el-button size="medium" type="primary" @click="onSubmit()">保存 </el-button>
          </div>
           
        </div>
        <el-dialog
          title="新增做法"
          :visible.sync="showDialog"
          width="600px"
          height="1000px"
        >
        <div class="dia">
          <div class="diali">
            <div class="liname">标题</div>
            <el-input v-model="content" type="text" placeholder="请输入"></el-input>
          </div>
          <div class="but" style="text-align: center;">
            <el-button size="medium" @click="showDialog = false">取消 </el-button>
            <el-button size="medium" type="primary" @click="bczf()">保存 </el-button>
          </div>
        </div>
        
    </el-dialog>
    <el-dialog title="选择商家" :visible.sync="userDialog" width="500px" height="400px">
      <div class="dia">
        <el-table :data="restaurants" style="width: 100%" height="calc(100vh - 480px)"
          @selection-change="handleSelectionChange" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
          <el-table-column type="selection" width="50"> </el-table-column>


          <el-table-column prop="businessName" label="商家名称" align="center">
          </el-table-column>
         
          <el-table-column prop="creatTime" label="创建时间" align="center">

          </el-table-column>


        </el-table>


        <div class="diafour" style="text-align: center;">
          <el-button type="danger" @click="userDialog = false">取消</el-button>
          <el-button type="primary" @click="userDialog = false">确定 </el-button>
        </div>
      </div>

    </el-dialog>
    <el-dialog title="商品分类" :visible.sync="userDialogfl" width="500px" height="400px">
      <div class="dia">
        <el-table :data="restaurantsfl" style="width: 100%" height="calc(100vh - 480px)"
          @selection-change="handleSelectionChangefl" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
          <el-table-column type="selection" width="50"> </el-table-column>


          <el-table-column prop="classifyName" label="分类名称" align="center">
          </el-table-column>
         
          <el-table-column prop="creatTime" label="创建时间" align="center">

          </el-table-column>


        </el-table>


        <div class="diafour" style="text-align: center;">
          <el-button type="danger" @click="userDialogfl = false">取消</el-button>
          <el-button type="primary" @click="userDialogfl = false">确定 </el-button>
        </div>
      </div>

    </el-dialog>
    <el-dialog title="商品二级分类" :visible.sync="userDialogtwofl" width="500px" height="400px">
      <div class="dia">
        <el-table :data="fltwolist" style="width: 100%" height="calc(100vh - 480px)"
          @selection-change="handleSelectionChangetwofl" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
          <el-table-column type="selection" width="50"> </el-table-column>


          <el-table-column prop="classifyName" label="分类名称" align="center">
          </el-table-column>
         
          <el-table-column prop="creatTime" label="创建时间" align="center">

          </el-table-column>


        </el-table>


        <div class="diafour" style="text-align: center;">
          <el-button type="danger" @click="userDialogtwofl = false">取消</el-button>
          <el-button type="primary" @click="userDialogtwofl = false">确定 </el-button>
        </div>
      </div>

      </el-dialog>
        <!-- 剪裁组件弹窗 -->
        <el-dialog
            title="裁切图片"
            :visible.sync="cropperModel"
            width="1200px"
            center
          >
        <cropper-image
          v-if="cropperModel"
          :Name="cropperName" :New="cropperModel"
          :AutoCropWidth="AutoCropWidth" :AutoCropHeight="AutoCropHeight" :FixedNumber="FixedNumber"
          @uploadImgSuccess = "handleUploadSuccess"
          ref="child">
        </cropper-image>
      </el-dialog>
      <!--查看大封面-->
      <el-dialog
          title="查看图片"
          :visible.sync="imgVisible"
          class="previewDialog"
          center>
        <img :src="oldpreview" v-if="imgVisible" style="width: 100%" alt="查看">
      </el-dialog>
    </div>
  </template>
  
  <script>
import CropperImage from "@/components/CropperImage.vue";
 export default {
  components:{
    CropperImage
  },
  data() {
    return {
      commoditySpecsList:[{
        specificationName:'',
        inventory:'',
        retailPrice:'',
        realPrice:'',
        packingPrice:''
        }],
        commodityMethodList:[],
        form: {
          mainImg:'',
          logoImg:'',
          buyVideo:'',
          bigImg:'',
          businessName:''
        },
        tableData:[],
        showDialog:false,
        img1:'',
        dia:{
          content:'',
          price:'',
          number:''
        },
        restaurantsfl:[],
        userDialogfl:false,
        fltwolist:[],
        userDialogtwofl:false,
        focusindex:'',
        dialist:[],
        tags: [
          { name: '标签一', type: '' },
          { name: '标签二', type: 'success' },
          { name: '标签三', type: 'info' },
          { name: '标签四', type: 'warning' },
          { name: '标签五', type: 'danger' }
        ],
        dynamicTags: ['标签一', '标签二', '标签三'],
        inputVisible: null,
        inputValue: '',
        content:'',//做法名称
        restaurants:[],
        userDialog:false,
        list:[],
        pageNum: null,
        //裁切图片参数
        cropperModel:false,
        cropperName:'',
        AutoCropWidth: null,//宽
        AutoCropHeight: null,//高
        FixedNumber: null,//比例
        oldpreview: '',//预览图
        imgVisible: false

    };
  },
  created(){
    
    console.log(this.$route.query.id);
    var that = this;
    that.$http.post('/business/queryBusinessList',{}).then(response => {
      console.log(response.data.data,'response.data.data');
      
        that.restaurants = response.data.data
        console.log(that.restaurants,'that.restaurants');
        
        
        
      })
    if(this.$route.query.id){
      this.id = this.$route.query.id
      this.pageNum = this.$route.query.pageNum
      this.$http.post('/commodity/queryOne',{
        id:this.id
      }).then(response => {
        that.form = response.data.data;
        that.commoditySpecsList = response.data.data.commoditySpecsList;
        that.commodityMethodList = response.data.data.commodityMethodList;
        that.list = response.data.data.bigImgList.filter(item=>item.length>0)
        // 编辑页弹窗
        that.form.belongBusiness= that.form.businessId
        that.form.belongBusinessName= that.form.businessName
        that.$http.post('/commodityType/queryCommodityTypeListByBusinessId',{
          businessId: that.form.businessId,
          commodityType:2,
        }).then(response => {
          that.restaurantsfl = response.data.data
          let _row = that.restaurantsfl[that.restaurantsfl.findIndex(item=>item.classify == that.form.parentClassify)]
          that.fltwolist = _row.commodityTypeSonList;
        })
      })
    }
    

  },
  mounted() {
    
  },
  methods: {
    limitInputLength() {
      if (this.inputValue.length > 4) {
        this.$message.success('最多输入4个字')
        this.inputValue = this.inputValue.slice(0, 4);
      }
    },
    handleSelectionChange(val){
      this.form.belongBusiness= val[0].id
      this.form.belongBusinessName= val[0].businessName
      console.log(val,'-0-00-0',this.form);

    },
    //图片裁剪设置
    uploadPicture(name){
      console.log('???',name)
      this.cropperName = name;
      switch(name){
        case 'bigImgList':
          this.AutoCropWidth = 375//宽
          this.AutoCropHeight = 171.5//高
          this.FixedNumber = [750,343]//比例
          break;
        case 'logoImg':
          this.AutoCropWidth = 150//宽
          this.AutoCropHeight = 150//高
          this.FixedNumber = [1,1]//比例
          break;
      }
      this.cropperModel = true;
    },
    //图片上传成功后
    handleUploadSuccess (data){
      switch(this.cropperName){
        case 'bigImgList':
          this.list.push(data.url)
          console.log('最终输出'+this.cropperName)
          break;
        case 'logoImg':
          this.form.logoImg = data.url;
          console.log('最终输出'+this.cropperName)
          break;
      }
      console.log('success',data,this.list,this.list[0].length>0)
      this.cropperName = ''
      this.cropperModel = false;
    },
    //图片预览
    onPreview(url){
      this.oldpreview = url
      this.imgVisible = true;
    },
    focus(index){
      var that = this;
      that.focusindex = index
      this.userDialog = true

    },
    focusfl(index){
      var that = this;
      console.log(this.form.businessId);
      that.focusflindex = index
      that.$http.post('/commodityType/queryCommodityTypeListByBusinessId',{
        businessId: that.form.belongBusiness,
        commodityType:2,
      }).then(response => {
        that.restaurantsfl = response.data.data
      })
      that.userDialogfl = true
    },
    focusfltwo(){
      this.userDialogtwofl = true
    },
    // handleSelectionChange(val){
    //   console.log(val);
      
    //   // this.form.businessName= val[0].businessName
    //   if(this.focusindex == 1){
        
    //     this.form.belongBusiness= val[0].businessName
    //     this.businessId = val[0].id;
    //   } else {
    //     this.form.businessName= val[0].businessName
    //   }
      

    // },
    // 一级分类
    handleSelectionChangefl(val){
      console.log(val,'val');
      
      this.fltwolist = val[0].commodityTypeSonList;
      this.form.parentClassifyName= val[0].classifyName
      this.form.parentClassify = val[0].classify

    },
    // 二级分类
    handleSelectionChangetwofl(val){
      this.form.classifyName= val[0].classifyName
      this.form.classify= val[0].classify
    },
    querySearch(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        console.log(results,'results');
        
        cb(results);
      },
      createFilter(queryString) {
        
        return (restaurant) => {
          return (restaurant.businessName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
          
        };
       
        
      },
      handleSelect(item) {
        console.log(item);
      },
    handleSuccess(response, file, fileList) {
      this.list.push(response.data.url)
      console.log(response.data.url);
    },
    onSubmit(){
      var that = this;
        // this.form.commodityDetailList = this.commodityDetailList
      this.form.commoditySpecsList = this.commoditySpecsList  //规格
      this.form.commodityMethodList = this. commodityMethodList  //做法
      that.form.bigImgList = that.list
      that.form.id = this.id
      that.$http.post("/commodity/saveTakeaway", that.form).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          console.log('999');
          that.$router.push({path:'/takeaway',query:{'pageNum':that.pageNum}})
          that.$message({
            type:'success',
            message: '保存成功'
          });
        }
      });
    },
    close(){
      this.$router.push({path:'/takeaway',query:{'pageNum':this.pageNum}})
      // this.$router.go(-1)
    },
    add(){
        this.showDialog = true
    },
    handleAvatarSuccess(res, file) {
      console.log(file.response.data.url,'88888888');
        this.form.bigImg = file.response.data.url;
        console.log(this.form.bigImg,99999999999);
    },
    handleAvatarSuccesstwo(res, file) {
      console.log(file.response.data.url,'88888888');
        this.form.buyVideo = file.response.data.url;
        
    },
    handleAvatarSuccessthree(res,file){
      this.form.mainImg = file.response.data.url;
    },
    handleAvatarSuccessfour(res,file){
      this.form.logoImg = file.response.data.url;
    },
    bc(){
      
      this.dialist.push(this.dia)
      this.dia = {
        content:'',
        price:'',
        number:''
      }
      this.showDialog = false
    },
    //新增规格
    addgg(){
      
      this.commoditySpecsList.push({
        specificationName:'',
        inventory:'',
        retailPrice:'',
        realPrice:'',
        packingPrice:''
        })
    },
    //新增做法
    addzf(){
      this.showDialog = true
      // this.commodityMethodList.push({
      //     content:'标题',
      //     methodList:['T','DT']
      //   })
    },
    bczf(){
        this.commodityMethodList.push({
          content:this.content,
          methodStringList:[]
        })
        this.showDialog = false
    },
    handleClose(i,tag) {
      this.commodityMethodList[i].methodStringList.splice(this.commodityMethodList[i].methodStringList.indexOf(tag), 1);
      },

      showInput(i) {
        this.inputVisible = i;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },
      deleteMethod(i){
        this.commodityMethodList.splice(i, 1)
        console.log('Methodlist',this.commodityMethodList)
      },

      handleInputConfirm(i) {
        console.log(i,'iiiiiiii');
        let inputValue = this.inputValue;
        if (inputValue) {
          this.commodityMethodList[i].methodStringList.push(inputValue);
        }
        this.inputVisible = null;
        this.inputValue = '';
      },
      emitUpload() {},
      handelRemove(val){
      if(val == 'logo'){
        this.form.logoImg = ''
      }else{
        this.list.splice(val, 1,)
      }
    },
  }
};
  </script>
  
  <style lang="less" scoped>
  .smalltext{
    font-size: 13px;
    color: #606266;
  }
  /deep/.el-main {
  padding: 0 !important;
  height: 100% !important;
  background-color: #fff !important;
}
  .box {
    height: 1400px;
    background-color: #fff;
    padding-top: 20px;
    .jbxx {
        .jbtop {
            width: 1559px;
            height: 56px;
            background-color: #eef1f6;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding-left: 20px;
            margin-left: 10px;
            margin-bottom: 40px;
            .icon {
                width: 6px;
                height: 19px;
                border-radius: 10px;
                background-color: #f77f1f;
                margin-right: 10px;
            }
            .tit {
                font-weight: 700;
            }
        }
        
    }
    .butt {
        display: flex;
        justify-content: space-between;
        padding: 50px 300px;
    }
    .tablist-btn{
      margin-left: 20px;
    }
    .tab {
        margin: 10px 70px 10px 70px;
        padding: 5px 0;
        border: 1px solid #f1f3f8;
        .tablist {
          display:flex;
          margin-left:10px;
          width: 100%;
          padding: 5px;
          // border: 1px solid #ccc;
          .listleft {
            margin-right:20px;
            padding-left: 10px;
            min-width: 50px;
          }
          .listright{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            .input-new-tag{
              width: 120px;
              margin-right: 12px;
            }
          }

        }
    }
    .dia {
      .but {
            display: flex;
            justify-content: space-between;
            padding: 30px 200px;
        }
      .diali {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .liname {
            width: 100px;
        }
        input {
            height: 30px;
        }
      }
      
    }
  }
  /deep/ .avatar-uploader .el-upload {
    // border: 1px dashed #877b7b;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    border: 1px solid #877b7b;
    // display: block;
  }
  .ggli {
    margin-right: 20px;
  }
  /deep/ .el-tag--small {
    
    margin-right: 15px;
  }
  ::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
.imgss {
  margin: 5px;
}
.bigImg {
  width: 500px;
}

.fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding-top: 20px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 2;
}
.container {
  padding: 5px;
  box-sizing: border-box;
  .box {
    width: 100%;

    background-color: #fff;
    font-size: 14px;
    border-radius: 5px;
    color: #333;
    .title {
      border-bottom: 1px solid #eee;
      padding: 10px;
      box-sizing: border-box;
      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 20px;
        margin-right: 15px;
        background-color: #02a7f0;
        // 和标题对其
        vertical-align: middle;
      }
    }
    .content2 {
      padding: 0px 20px 20px 20px;
      box-sizing: border-box;
    }
    .content {
      padding: 0px 20px;
      box-sizing: border-box;

      .imgTitle {
        margin-top: 10px;
        box-sizing: border-box;
        font-weight: 700;
        padding-bottom: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
      }
      .imgbox {
        padding: 10px;
        box-sizing: border-box;
      }

      .fa {
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding-left: 15px;
        box-sizing: border-box;
        background-color: #e3f5fd;
        margin: 10px;
      }
    }
  }
}

.mp {
  margin-top: 15px;
}
.mp10 {
  margin-top: 10px;
}
.pd50 {
  padding: 0 50px;
  box-sizing: border-box;
}
.left10 {
  padding-left: 20px;
  box-sizing: border-box;
}
.tp5 {
  margin-top: 5px;
  width: 148px;
  text-align: center;
  font-weight: 700;
  color: #8c939d;
}
.imgs {
  width: 130px;
  height: 130px;
  margin: 5px;
}
.center {
  width: 600px;
  text-align: center;
  margin: 10px auto;
}

.lable {
  width: 130px;
  display: inline-block;
  text-align: end;
}

.imgBox {
  width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.img-icon {
  width: 148px;
  height: 148px;
  // width: 750px / 3;
  // height: 343px / 3;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar-uploader {
  width: 100%;
}

.avatar-uploader ::v-deep .el-upload {
  //background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #c0ccda;
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

/deep/.el-loading-spinner {
  margin-top: -74px;
}

.avatar {
  display: block;
  width: 148px;
  height: 148px;
  border-radius: 6px;
}

.imgBox {
  width: 148px;
  height: 148px;
  // width: 750px / 3;
  // height: 343px / 3;
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.table {
  .el-button {
    height: 20px !important;
    line-height: 6px !important;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}
.text {
  text-align: center;
  margin-top: 10px;
}
::v-deep .el-table td {
  padding: 0 !important;
}
::v-deep .el-table th {
  padding: 0px !important;
}
.pdfbox {
  display: flex;

  .item {
    margin: 0 10px;
  }
}
.imgboxs {
  margin-left: 130px;
  margin-top: 5px;
  display: flex;
  & > div {
    margin: 0 10px;
  }
}
::v-deep .el-image {
  margin: 5px !important;
}

// .titlesss::before {
//   content: "*";
//   color: red;
//   position: absolute;
//   left: 2px;
// }
.titlesss {
  color: red;
  display: inline-block;
  margin-right: 3px;
}

.success {
  color: #62a003;
  font-weight: 700;
}

.error {
  color: #d6000a;
  font-weight: 700;
}
.shenhebox {
  height: 500px;
  overflow: auto;
}
.tag {
  width: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
}
  .ggli {
    margin-right: 20px;
    input {
      border: 1px solid #b8becc;
      height: 25px;
      border-radius: 3px;
    }
  }
  /deep/ .el-tag--small {
    
    margin-right: 15px;
  }
  .list-img-box{
  width: 150px;
  height: 150px;
  border-radius: 6px;
  border: 1px dashed #c0ccda;
  color: #8c939d;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .upload-btn{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.upload-img{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
  </style>
  